.container{
    width: 550px;
    min-height: 398px;
    margin: 20px 20px 0px 20px;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    padding: 30px;
}

.title{
    text-align: left;
    font: normal normal 900 26px/29px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
}

.titleCommercial{
    text-align: left;
    font: normal normal 900 26px/29px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
}

.sub{
    text-align: left;
    font: normal normal 800 16px/29px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
}

.price{
    text-align: left;
    font: normal normal 900 22px/29px Nunito;
    letter-spacing: 0px;
    color: #DA291C;
    opacity: 1;
}

.cover{
    text-align: left;
    font: normal normal 800 16px Nunito;
    letter-spacing: 0px;
    margin-top: 20px !important;
    color: #DA291C;
    opacity: 1;
}


.cover1{
    text-align: left;
    font: normal normal 800 16px Nunito;
    letter-spacing: 0px;
    color: #DA291C;
    opacity: 1;
}


.coverNumber{
    text-align: left;
    font: normal normal 800 22px Nunito;
    letter-spacing: 0px;
    color: #DA291C;
    opacity: 1;
}

.excess{
    text-align: left;
    font: normal normal bold 16px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
    margin-bottom: 20px !important;
}

.turnover{
    text-align: left;
    font: normal normal bold 16px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
}

.turnoverNumber{
    text-align: left;
    font: normal normal bold 22px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
}

.small{
    color: #5A727A;
    font-size: 12px;
}

.description{
    text-align: left;
    font: normal normal normal 16px/24px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
    margin-top: 20px !important;
}


.description_1{
    text-align: left;
    font: normal normal normal 16px/24px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
}

@media screen and (max-width: 430px) {
    .container{
        width: 100%;
        margin: 0px;
    }

    .titleCommercial{
        text-align: center;
    }
}


/*  <-------------- SUMMARY CARD ----------------> */

.policyPrimaryContainer{
    width: 100% ;
}

.policyPrimary{
    text-align: left;
    font: normal normal 900 22px/29px Nunito;
    letter-spacing: 0px;
    font-size: 1.05rem;
    color: #DA291C;
    opacity: 1;
}

.policySecondaryContainer {
    width: 100%;
}

.spanText{
    font-size: 0.8rem;
    transform: translateY(-30%);
    font-weight: 500;
}

.policySecondary {
    text-align: left;
    font: normal normal bold 16px Nunito;
    letter-spacing: 0px;
    color: #5A727A;
    opacity: 1;
    font-size: 1.05rem;
}

.boxBorder{
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    min-height: 200px;
    margin-top: 50px;
    padding: 30px;
}