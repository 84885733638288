.body__btn__hover:hover {
  background: #dd302a !important;
  /* font-weight: 600; */
}

.button__header {
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  align-items: center;
}

.button__header_hover:hover {
  color: #dd302a !important;
}
