.logo {
  height: auto;
}

.topnavbar {
  background: var(--tertiary);
}

.topnavbar_row {
  margin: auto;
  color: white;
}

.appbar1__content > a {
  color: white;
  margin-left: 2rem;
}

.footer_container {
  background: #ffffff;
  padding: 30px;
  color: var(--font-secondary);
  margin-top: 20px;
  height: 240px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #82848680;
}

.footer_container_xs {
  background: var(--tertiary);
  padding: 30px;
  color: white;
  margin-top: 20px;
  height: 590px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer_header {
  font-weight: 700;
}

.footer_social {
  color: var(--secondary);
}

.header_navs {
  /* display: flex; */
  margin-right: 3rem;
  justify-content: space-between;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .header_navs {
    width: 50%;
  }
}
