/* <------ Nav links Override styles ------> */

.nav-link.active {
  color: red !important;
}

p {
  margin: 0px !important;
}

b{
  font-weight: 800 !important;
}

strong{
  font-weight: 800 !important;
}

button {
  border: none;
}
footer_exclude input,
select {
  text-align: left;
  font: normal normal bold 16px/29px Nunito !important;
  letter-spacing: 0px;
  color: #5a727a;
}

input::placeholder {
  opacity: 0.5 !important;
}

.nav-link {
  font-family: var(--primary-font);
  font-weight: 800;
  font-size: 1.2rem;
}

.nav-link:hover {
  color: var(--primary) !important;
}

.error-input {
  border: 1px solid rgb(218 41 28 / 25%) !important;
  box-shadow: 0 0 0 0.25rem rgb(218 41 28 / 25%) !important;
}

.errors {
  color: #da291c;
  font-size: 0.8rem;
}

/* <------- Divider ------> */

.divider {
  background-color: #d0d0d0;
  height: 2px;
}

/* <----- Form label override style ----->  */

.form-label {
  color: #5a727a;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
}
.dashed {
  padding-left: 10px !important;
}

ul.dashed {
  list-style-type: "-";
}

ul.dashed > li {
  padding: 0px 0px 0px 10px;
}

.t_c_text {
  color: #da291c;
  font-weight: 600;
}

/* <----- Stepper override style ----->  */

.MuiStepIcon-root.Mui-completed,
.MuiStepIcon-root.Mui-active,
.MuiStepLabel-label.Mui-completed {
  color: #da291c !important;
}

.MuiStepLabel-label {
  font: normal normal normal 14px/26px Nunito !important;
  letter-spacing: 0.11px;
  color: #5a727a;
  opacity: 1;
}

.textarea-label {
  color: #5a727a;
  height: 50px !important;
  margin-top: 10px;
}

.MuiFormControlLabel-label {
  text-align: left;
  font: normal normal normal 16px/29px Nunito !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
}

/* <------- Header Styles --------> */

.header__title {
  text-align: left;
  font: normal normal bold 16px/25px Nunito;
  letter-spacing: 0px;
  line-height: 30px !important;
  opacity: 1;
}
.header__title {
  text-align: left;
  font: normal normal bold 16px/25px Nunito;
  letter-spacing: 0px;
  opacity: 1;
}

.header__sub {
  text-align: left;
  font: normal normal 800 16px/29px Nunito;
  letter-spacing: 0px;
  color: #6e6f6c;
  opacity: 1;
}

.description_placeholders {
  text-align: left;
  font: normal normal normal 16px/24px Nunito;
  letter-spacing: 0px;
  color: #6e6f6c;
  opacity: 1;
}

.list > li {
  text-align: left;
  font: normal normal bold 16px/26px Nunito;
  letter-spacing: 0.16px;
  color: #5a727a;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 3em;
  border-width: 2px;
  height: 3em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgb(218, 41, 28);
  border-right: 0.5em solid rgb(218, 41, 28);
  border-bottom: 0.5em solid rgb(218, 41, 28);
  border-left: 0.5em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.gif-loader {
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

.gif-loader-full {
  width: 100%;
  height: 90vh;
  margin: auto;
  align-items: center;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

.color-red{
  color: #DA291C;
}

/* --------Homepage----------------------- */

.homepage1__heading1 {
  line-height: 1.2;
}

.homepage1__heading2 {
  color: var(--tertiary);
}

.homepage__main__text {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.card__header {
  color: white;
  font-size: var(--font-size-h2);
  font-weight: 800;
  display: flex;
  align-items: center;
  /* width: 70% !important; */
}

.card__header__container {
  background: var(--secondary);
  border-radius: 10px;
  align-items: center;
  padding: 5px;
  /* margin-right: 30px; */
}

.card_header_img {
  width: 100px;
  padding: 5px 10px;
  border-radius: 10px;
  background: white;
  text-align: center;
}

.card__homepage {
  width: 100% !important;
  border-radius: 10px;
}

.card__list__header {
  color: var(--secondary);
  font-weight: 800;
}

.card__list {
  color: var(--font-secondary);
  font-size: 14px;
  border-bottom: 1px solid #d2d1d1;
  padding-bottom: 20px;
  padding-left: 15px;
}

.card__list2 {
  padding-bottom: 0px;
}

.card__list2 > li {
  margin-bottom: 20px;
}

.card__list > li > span:first-child {
  font-weight: 600;
}

.card__list > li > div:first-child {
  font-weight: 600;
}

.card__footer {
  color: var(--font-secondary);
}

.card__footer > span:first-child {
  font-weight: 700;
}

.card__footer > span:last-child {
  font-weight: 700;
  color: var(--font-primary);
  cursor: pointer;
}

.card__footer > span:last-child:hover {
  color: var(--primary);
}

.card__footer__bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
}

.amount {
  font-size: 1.4rem;
  color: var(--base);
}

.amount > span:last-child {
  font-weight: 800;
}

.card__avatar__text {
  text-align: left;
  font-weight: 700;
  color: var(--primary);
  font-size: 1.2rem;
}

.card__avatar__text > div:first-child {
  font-size: 14px;
  font-weight: 700;
  color: var(--secondary);
}

.card__avatar__img {
  width: 200px;
}

.card__avatar__text > div:last-child {
  font-size: 14px;
  color: var(--secondary);
}

.card__imp__text {
  font-weight: 600;
  text-align: left;
  font-family: var(--secondary-font);
}

.card__amount {
  text-align: left;
  color: var(--primary);
}

.card__amount > strong {
  font-size: 21px;
  font-weight: 900;
}

.card__list2__un {
  list-style-type: none;
  border: none;
}
.bullet {
  text-decoration: dotted;
}

.card__footer2 {
  color: var(--font-secondary);
  text-align: left;
}

.card__footer2 > span {
  font-weight: 700;
  color: var(--font-primary);
  cursor: pointer;
}

.card__footer2 > span:last-child:hover {
  color: var(--primary);
}

.font-weight-bold {
  font-weight: bold;
}

/* -----------------Content------------ */

.content {
  color: var(--secondary);
}

/* ------------------Breadcrumb------------------ */
.breadcrumbs {
  padding-top: 15px;
}

.breadcrumbs > a {
  color: var(--font-secondary);
  text-decoration: none;
}

.breadcrumbs > a > svg {
  font-size: 14px;
}

/* ----------Accordion------------ */
.MuiAccordionSummary-root.Mui-expanded {
  background-color: var(--secondary) !important;
  min-height: auto !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0px !important;
}

.MuiAccordionSummary-root {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background: white !important;
}

.Mui-expanded > svg {
  background-color: var(--secondary) !important;
  color: white !important;
}

.Mui-expanded > div > p {
  color: white !important;
}

.MuiAccordionDetails-root {
  background: #f9f9f9;
  color: var(--font-secondary);
}
.gif-loader {
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

.gif-loader-fullscreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  margin: auto;
  align-items: center;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

.lds-dual-ring {
  display: inline-block;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  margin: 5px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.footer_logo_lg {
  width: 150px;
  /* margin-bottom: 20px; */
  background: #ffffffc7;
  padding: 5px;
}

.footer_sec_logo {
  background: #ffffffc7;
  padding: 5px;
}

/* Keyframe animation for loader */
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* -------------Footer---------------- */
.footer_logo {
  max-width: 250px;
  margin-bottom: 20px;
  background: #ffffffc7;
  padding: 5px;
}

/* -----------------contact-------------- */
.contact_submit {
  padding: 10px 20px;
  border-radius: 100px;
  background: var(--primary);
  color: white;
  box-shadow: 2px 2px 8px #6d6565;
  margin-top: 20px;
}

.main__img {
  width: 96%;
}

.stats-img {
  width: 90%;
}

.app__header__two__sub {
  color: #5a727a;
}


ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "-";
  text-indent: -5px;
}
@media only screen and (max-width: 430px) {
  .card__avatar__text,
  .card__imp__text,
  .card__amount {
    text-align: center;
  }
}

/* Media query for mobile screens */
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  /* changing homepage image width */
  .main__img {
    width: 160px;
  }

  /* Changing width of card in homepage */
  .card__homepage {
    width: 100% !important;
  }
  /* Changing width of card header */
  /* 
  .card__header {
    width: 100% !important;
  }
  */

  /* Font-size changed selecting the first row */
  .homepage__main__text > .row:first-child {
    font-size: 26px;
    text-align: center;
  }

  .homepage1__heading2 {
    text-align: center;
  }

  .app__header__two {
    text-align: center;
    font-size: 26px;
  }

  .app__header__two__sub {
    text-align: center;
    font-size: 18px;
  }

  /* displaying footer on mobile screen */
  .card__footer__bottom {
    display: block;
  }

  /* Changing the width of the card footer */
  .card__footer__bottom > div:last-child {
    width: auto;
  }

  /* Changing card image */
  .card__avatar__img {
    width: 130px;
  }

  .card__list__header {
    text-align: center;
  }

  .card__list2__un {
    font-size: 16px;
  }

  /* changing font size of amount */
  .amount {
    font-size: 1rem;
    margin-right: 10px;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: column !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* Changing card image */
  .card__avatar__img {
    width: 170px;
  }
}
