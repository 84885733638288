.desc_table {
  max-width: 850px;
}

.desc_table_col_1 {
  min-width: 430px;
  border-right: 1px solid #5a727a75;
}

.desc_table_col_2 {
  border-right: 1px solid #5a727a75;
}
