.header {
  text-align: center;
  font: normal normal 800 30px/29px Nunito;
  letter-spacing: 0px;
  font-family: "Nunito", sans-serif;
  color: #6e6f6c;
  opacity: 1;
}

.boxBorder {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  min-height: 200px;
  margin-top: 50px;
  padding: 30px;
}

.boxBorderCommercial {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  min-height: 200px;
  margin-top: 50px;
  padding: 30px;
}

.boxBorder h4,
h6 {
  color: #5a727a;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
}

.mandateInfo {
  margin: 2rem 1.5rem;
}

.mandateInfo > p {
  font-weight: 500;
  margin-bottom: 2rem !important;
}

.paymentButton {
  padding: 10px;
  text-align: center;
  border: 1px solid #0b749c;
  border-radius: 10px;
  font-weight: 900;
  font-size: 1rem;
  color: #0b749c;
  cursor: pointer;
}

.paymentButton:hover {
  background: #0b749c;
  color: white;
}


@media only screen and (max-width: 768px) {
  .header{
    font: normal normal 800 18px/18px Nunito;
  }

  .boxBorderCommercial {
    
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
  } 
}